<template>
  <object
    scroll="no"
    style="width: 100%; height: 100vh; overflow-x: hidden !important"
    scrolling="no"
    type="text/html"
    data="https://app.powerbi.com/view?r=eyJrIjoiNWVhNjA3ZGItNjAzNi00NDYwLTllNjQtNDM3Y2E0ZDdkMGM1IiwidCI6ImMzNmJlZGI5LTAwMzgtNDM0OC1iYzRjLTg4ZWQ4MWZjMTQ1OCIsImMiOjR9"
  ></object>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Desincorporacion",
  data() {
    return {};
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Desincorporación"))
      this.$router.push("/dashboard");
  },
};
</script>

<style>
</style>
